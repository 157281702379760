<template>
  <div class="container-fluid">
    <div class="row d-flex justify-content-around align-items-center">
      <div class="col-3 text-left">
        <img class="w-75" src="/assets/images/logo.svg" />
      </div>
      <div class="col-6 text-center">
      </div>
      <div class="col-3 text-right">
        <a @click="$router.go(-1)" href="#" class="btn btn-outline-secondary d-block d-print-none">Volver atrás</a>
      </div>
    </div>
    <div class="row mt-4 text-center">
      <div class="col-12">
        <h3 class="header-title mb-0 mt-3 text-center text-uppercase">Reporte de Finanzas</h3>
      </div>
      <div class="col-12 my-5 d-print-none">
        <div class="row justify-content-start align-items-center">
          <div class="col-12 col-md-3">
            <label>Desde</label>
            <datetime v-model="filters.date_from" :config="dateConfig" id="date_from" class="form-control"></datetime>
          </div>
          <div class="col-12 col-md-3">
            <label>Hasta</label>
            <datetime v-model="filters.date_to" :config="dateConfig" id="date_to" class="form-control"></datetime>
          </div>
          <div class="col-12 mt-4">
            <div class="row justify-content-end align-items-center">
              <div class="col-12 col-md-auto">
                <button class="btn btn-sm btn-info" type="button" @click.prevent="doFilter">Filtrar</button>
              </div>
              <div class="col-12 col-md-auto">
                <button class="btn btn-sm btn-outline-info" type="button" onclick="window.print();">Imprimir</button>
              </div>
              <div class="col-12 col-md-auto">
                <button class="btn btn-sm btn-outline-secondary" type="button" @click.prevent="resetFilter">Limpiar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <table class="table border">
          <tr>
            <th class="border">Facturas Emitidas</th>
            <th class="border">Gastos sin Facturar</th>
            <th class="border">Gastos de la Firma</th>
            <th class="border">Gastos de Expediente</th>
          </tr>
          <tr>
            <td class="border">{{financesReport.invoices | formatMoney}}</td>
            <td class="border">{{financesReport.expenses_no_invoiced | formatMoney}}</td>
            <td class="border">{{financesReport.bills | formatMoney}}</td>
            <td class="border">{{financesReport.expenses_invoiced | formatMoney}}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row d-flex justify-content-end mt-5">
      <div class="col-3">
          <div class="row">
            <div class="col-6 h4">Balance</div>
            <div class="col-6 h4">{{ ( (Number(financesReport.invoices) + Number(financesReport.expenses_no_invoiced)) ) - ( Number(financesReport.bills) + Number(financesReport.expenses_invoiced) ) | formatMoney}}</div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FinanceReport',
  data () {
    return {
      dateConfig: {
        altInput: true,
        enableTime: false,
        altFormat: 'F j, Y',
        dateFormat: 'Y-m-d',
        time_24hr: false,
        locale: Spanish // locale for this instance only,
      },
      filters: {
        date_from: this.$moment().format('YYYY-MM-DD'),
        date_to: this.$moment().add(30, 'days').format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    ...mapActions({
      getFinancesReport: 'Report/getFinancesReport',
      resetReport: 'Report/reset'
    }),
    resetFilter () {
      this.filters.date_from = this.$moment().format('YYYY-MM-DD')
      this.filters.date_to = this.$moment().add(30, 'days').format('YYYY-MM-DD')
      this.resetReport()
      this.getFinancesReport(this.filters)
    },
    doFilter () {
      this.getFinancesReport(this.filters)
    }
  },
  computed: {
    ...mapGetters({
      financesReport: 'Report/finances'
    })
  }
}
</script>
